import React from 'react'
import Layout from "../templates/Layout"
import { Hero, Container, Row, } from "@edinburghairport/runway"
import '../scss/main.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class RootIndex extends React.Component {

  render() {

    const content = {
      background: {
        file: {
          url: "https://edinburghairport.s3-eu-west-1.amazonaws.com/files/acp2019/home_bg.png"
        }
      },
      heading: "Page not found (Error 404)"
    };

    return (
      <Layout>
        <Hero content={content} />       
        <div className="section section__404">
          <Container>
            <Row>
            <div className="col-sm-12">
              <p>The page you have requested could not be found. That might be because:</p>

<ul>
  <li>The page has moved</li>
  <li>The page no longer exists</li>
  <li>There's a typing error in the address.</li>
</ul>
<p><a href="/">Go to home page</a></p>
           </div>     
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default RootIndex